.imgcontainer {
  .row img {
    height: 100%;
  }

  a {
    color: #333;
    text-decoration: none;
    font-weight: 700;

    &:hover {
      color: #333;
      text-decoration: none;
    }
  }
}

#bmgBanner .marquee {
  position: relative !important;
}

hr {
  border-color: #000 !important;
}

h2.entry-title {
  color: $primary-color;
}