#howto {
  @media (max-width: 767px) {
    .col-12 {
      margin-bottom: 10px;
    }
  }

  .howtotext {
    display: table;
  }

  .c1 {
    background: $primary-color;
    border: 1px solid #d8d8d8;
    border-radius: 50%;
    height: 100px;
    width: 100px;
    transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    float: left;
    margin-right: 10px;

    @media (max-width: 991px) {
      float: none;
      margin: auto;
      margin-bottom: 10px;
      height: 80px;
      width: 80px;
    }

    svg {
      fill: #fff;
      height: 40px;
      width: 40px;
      margin-top: 30px;
      margin-left: auto;
      margin-right: auto;
      display: block;

      @media (max-width: 991px) {
        height: 30px;
        width: 30px;
        margin-top: 23px;
      }
    }
  }

  .col-12:hover .c1 {
    background: #444;
  }

  h2 {
    color: $primary-color;
  }

  h3 {
    margin-bottom: 20px;
    font-size: 20px;
  }
}