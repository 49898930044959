@import "_global";

@import "sections/_navbar";
@import "sections/_header";
@import "sections/_footer";

@import "widgets/_midbar";
@import "widgets/_howto";
@import "widgets/_reviews";

@import "pages/_index";
@import "pages/_innerpages";

@import "_custom";