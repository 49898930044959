#reviews {
  .reviewtext {
    position: relative;

    p {
      display: block;
      height: 73px;
      overflow: hidden;
    }

    .ellipsis {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      text-align: center;
      margin: 0;
      padding: 30px 0;
      background-image: linear-gradient(to bottom, transparent, #eeeeee);
    }
  }

  .recommend {
    color: #027502;

    svg {
      fill: #027502;
      width: 15px;
      height: 15px;
    }
  }

  .rating {
    color: #444;

    svg {
      height: 15px;
      width: 15px;
    }
  }

  .bg-grey {
    padding: 0 20px;
  }

  a {
    text-decoration: none;
  }

  svg {
    fill: #FFCC00;
    height: 26px;
    width:26px;
  }

  h2 {
    color: $primary-color;
    padding-bottom: 20px;
  }
}