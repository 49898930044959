h2 {
  font-size: 1.7rem;
}

h3 {
  font-size: 1.35rem
}

iframe {
  border: none;
  width: 100%;
}

.booking-tool iframe {
  min-height: 185px;

  @media (max-width: 1501px) {
    min-height: 205px;
  }
}

.bg-grey {
  background: #eee;
}

.bg-primary {
  background: $primary-color !important;

  h2,
  p {
    color: #fff;
  }
}

.bg-overlay {
  background-image: linear-gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) ), url($site-url + '/static/img/overlay-bg.jpg');
  background-repeat: no-repeat;
  background-position-y: bottom;
  background-size: cover;

  h2,
  p {
    color: #fff;
  }
}

#topbanner {
  background-image: url($site-url + '/static/img/home-bg.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.text-primary {
  color: $primary-color !important;
}

.hidden {
  display: none;
}

#contact-details {
  a {
    color: #444;
    text-decoration: none;
  }

  svg {
    width: 35px;
    height: 35px;
  }
}

.bmg-review-widget {
  float: right;
  min-height: 470px;

  @media (max-width: 991px) {
    max-width: 100%;
    float: none;
  }

  .review-widget {
    @media (max-width: 991px) {
      max-width: 100%;
      margin: auto;
    }

    #large {
      background: rgba(41,59,78, 0.75);
      border: 0 solid;
      border-radius: 10px;
      text-shadow: none !important;

      .green {
        color: #3f9543;
      }
    }

    .review-widget-wrapper .main-content p {
      font-size:14px !important;
    }

    .footer .header-text,
    .review-widget-wrapper .header .header-text {
      color: #fff !important;
    }

    .footer {
      background: rgba(0,0,0,0.125) !important;
      color: rgb(238, 238, 238) !important;
    }
  }
}

.cover {
  width:100%;
  height: 100%;
  border-radius: 0px;
  cursor: pointer;
}

.playbtn {
  height: 60px;
  cursor: pointer;
  opacity: 0.9;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d( -50%, -50%, 0 );
}