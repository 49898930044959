#midbar .row {
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (min-width: 767px) {
    padding: 10px 0;
  }

  @media all and (-ms-high-contrast:active), all and (-ms-high-contrast:none) {
    display: block !important;
  }

  @supports (-ms-accelerator:true) {
    display: block !important;
  }

  .col-12 {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media all and (-ms-high-contrast:active), all and (-ms-high-contrast:none) {
      display: block !important;
    }

    @supports (-ms-accelerator:true) {
      display: block !important;
    }
  }

  h3 {
    color: #fff;
    font-size: 20px;
    margin-bottom: 0;
    text-transform: uppercase;

    @media (max-width: 767px) {
      text-align: center;
      margin-bottom: 10px;
    }
  }

  .btn {
    background: #444;
    border: 2px solid #444;
    color: #fff;
    font-weight: 600;
    padding: 8px 25px;
    width: 100%;
    overflow: auto;
    text-overflow: ellipsis;

    @media (max-width: 767px) {
      width:75%;
      margin: auto;
    }

    &:hover {
      background: $primary-color;
      text-decoration: none;
    }
  }
}