header {
  background-color: $secondary-color;
  color: #fff;
  #headertopbar {
    border-bottom: 1px solid #fff;

    .leftbar a {
      display: flex;
      flex-direction: row;
      justify-content: center;

      p,
      svg {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      svg {
        width: 25px;
        height: 30px;
        margin-right: 5px;
      }

      p {
        font-size: 16px;
        color: #fff;
        font-weight: 600;
        text-align: center;
      }
    }

    .rightbar {
      display: flex;
      flex-direction: row;
      justify-content: center;

      .locationtext {
        margin-left: 5px;
        font-style: italic;
        font-weight: 400;
      }

      svg,
      p {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      svg {
        width: 11px;
        height: 33px;
        margin-right: 5px;
      }

      p {
        font-size: 16px;
        text-align: center;
        font-weight: 600;
      }
    }

    p {
      margin-bottom: 0;
    }

    svg {
      fill: $tertiary-color;
    }
  }

  .logo {
    width: auto;
    max-height: 150px;
    margin: auto;
    display: block;
  }

  .telephone {

    h2 {
      font-size: 2rem;

      @media (max-width:767px) {
        text-align: center;
      }
    }

    a, a:hover {
      text-decoration: none;
    }

    svg {
      width: 25px;
      height: 25px;
      fill: #fff;
    }
  }
}